import Flag1 from '@/assets/img/flag/flag1.png';
import Flag10 from '@/assets/img/flag/flag10.png';
import Flag11 from '@/assets/img/flag/flag11.png';
import Flag12 from '@/assets/img/flag/flag12.png';
import Flag13 from '@/assets/img/flag/flag13.png';
import Flag14 from '@/assets/img/flag/flag14.png';
import Flag15 from '@/assets/img/flag/flag15.png';
import Flag16 from '@/assets/img/flag/flag16.png';
import Flag17 from '@/assets/img/flag/flag17.png';
import Flag18 from '@/assets/img/flag/flag18.png';
import Flag19 from '@/assets/img/flag/flag19.png';
import Flag2 from '@/assets/img/flag/flag2.png';
import Flag20 from '@/assets/img/flag/flag20.png';
import Flag21 from '@/assets/img/flag/flag21.png';
import Flag22 from '@/assets/img/flag/flag22.png';
import Flag23 from '@/assets/img/flag/flag23.png';
import Flag24 from '@/assets/img/flag/flag24.png';
import Flag25 from '@/assets/img/flag/flag25.png';
import Flag26 from '@/assets/img/flag/flag26.png';
import Flag27 from '@/assets/img/flag/flag27.png';
import Flag28 from '@/assets/img/flag/flag28.png';
import Flag29 from '@/assets/img/flag/flag29.png';
import Flag3 from '@/assets/img/flag/flag3.png';
import Flag30 from '@/assets/img/flag/flag30.png';
import Flag31 from '@/assets/img/flag/flag31.png';
import Flag32 from '@/assets/img/flag/flag32.png';
import Flag33 from '@/assets/img/flag/flag33.png';
import Flag4 from '@/assets/img/flag/flag4.png';
import Flag5 from '@/assets/img/flag/flag5.png';
import Flag6 from '@/assets/img/flag/flag6.png';
import Flag7 from '@/assets/img/flag/flag7.png';
import Flag8 from '@/assets/img/flag/flag8.png';
import Flag9 from '@/assets/img/flag/flag9.png';
import Map from '@/assets/img/for-every-child/wherewework_map3@2x.png';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React from 'react';
import styled from 'styled-components';

const Flex = styled.div`
  padding-top: 76px;

  & > li {
    width: 20%;
    margin-bottom: 32px;

    .flag {
      display: inline-block;
      position: relative;
      padding-left: 93px;
      line-height: 32px;

      &::before {
        content: '';
        display: block;
        width: 53px;
        height: 32px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }

      &.f1 {
        &::before {
          background-image: url(${Flag1});
        }
      }
      &.f2 {
        &::before {
          background-image: url(${Flag2});
        }
      }
      &.f3 {
        &::before {
          background-image: url(${Flag3});
        }
      }
      &.f4 {
        &::before {
          background-image: url(${Flag4});
        }
      }
      &.f5 {
        &::before {
          background-image: url(${Flag5});
        }
      }
      &.f6 {
        &::before {
          background-image: url(${Flag6});
        }
      }
      &.f7 {
        &::before {
          background-image: url(${Flag7});
        }
      }
      &.f8 {
        &::before {
          background-image: url(${Flag8});
        }
      }
      &.f9 {
        &::before {
          background-image: url(${Flag9});
        }
      }
      &.f10 {
        &::before {
          background-image: url(${Flag10});
        }
      }
      &.f11 {
        &::before {
          background-image: url(${Flag11});
        }
      }
      &.f12 {
        &::before {
          background-image: url(${Flag12});
        }
      }
      &.f13 {
        &::before {
          background-image: url(${Flag13});
        }
      }
      &.f14 {
        &::before {
          background-image: url(${Flag14});
        }
      }
      &.f15 {
        &::before {
          background-image: url(${Flag15});
        }
      }
      &.f16 {
        &::before {
          background-image: url(${Flag16});
        }
      }
      &.f17 {
        &::before {
          background-image: url(${Flag17});
        }
      }
      &.f18 {
        &::before {
          background-image: url(${Flag18});
        }
      }
      &.f19 {
        &::before {
          background-image: url(${Flag19});
        }
      }
      &.f20 {
        &::before {
          background-image: url(${Flag20});
        }
      }
      &.f21 {
        &::before {
          background-image: url(${Flag21});
        }
      }
      &.f22 {
        &::before {
          background-image: url(${Flag22});
        }
      }
      &.f23 {
        &::before {
          background-image: url(${Flag23});
        }
      }
      &.f24 {
        &::before {
          background-image: url(${Flag24});
        }
      }
      &.f25 {
        &::before {
          background-image: url(${Flag25});
        }
      }
      &.f26 {
        &::before {
          background-image: url(${Flag26});
        }
      }
      &.f27 {
        &::before {
          background-image: url(${Flag27});
        }
      }
      &.f28 {
        &::before {
          background-image: url(${Flag28});
        }
      }
      &.f29 {
        &::before {
          background-image: url(${Flag29});
        }
      }
      &.f30 {
        &::before {
          background-image: url(${Flag30});
        }
      }
      &.f31 {
        &::before {
          background-image: url(${Flag31});
        }
      }
      &.f32 {
        &::before {
          background-image: url(${Flag32});
        }
      }
      &.f33 {
        &::before {
          background-image: url(${Flag33});
        }
      }
    }
  }

  ${breakpoint(1160)} {
    & > li {
      .flag {
        padding-left: 8.02vw;
      }
    }
  }
  ${breakpoint(1000)} {
    & > li {
      width: 25%;
      .flag {
        padding-left: 8.02vw;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    & > li {
      width: 33.33%;
      .flag {
        padding-left: 69px;
      }
    }

    padding-top: 48px;

    .col {
      dt,
      dd {
        width: 100%;
        line-height: 1.6;

        strong {
          font-size: 16px;
          padding-left: 40px;
          &::before {
            width: 26px;
            height: 26px;
          }
        }
      }
      dd {
        padding-left: 40px;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    & > li {
      width: 50%;
    }
  }
`;

const Content = styled.div`
  header {
    padding: 64px 0;
    margin-bottom: 64px;
    border-bottom: 1px solid #b7b8ba;

    .haeder-dec {
      margin-top: 32px;

      strong {
        color: #1cabe2;
      }
    }
  }

  ${breakpoint(`mobile`)} {
    header {
      padding: 48px 0;
      margin-bottom: 48px;

      .haeder-dec {
        margin-top: 24px;
      }
    }
  }
`;
const WorkTab3: React.FC = () => (
  <Content>
    <Image pcSrc={Map} mobileSrc={Map} />
    <header>
      <h2>
        <Tit size="s2" color="sky">
          유니세프 기부를 통해 도움을 주는 32개 유니세프 국가위원회
        </Tit>
      </h2>
      <p className="haeder-dec">
        도움을 주는 32개 유니세프 국가위원회는 그 나라에서 유니세프를 대표하여
        전 세계 어린이 현황과 이를 개선하기 위한 유니세프 활동을 널리 알려
        기금을 모금하고 어린이 권리를 옹호합니다. 32개 유니세프 국가위원회가
        유니세프 본부에 보낸 기금은 각 나라의 경제 수준과 어린이 상황을 고려하여
        158개 개발도상국 유니세프 국가사무소에 분배됩니다.
        <br />
        <br />
        <strong>유니세프한국위원회</strong>는 도움을 주는 32개 유니세프
        국가위원회 중 하나로, 한국에서 유니세프를 대표해 유니세프의 이념을
        실현합니다. 전 세계 어린이를 위해 기금을 모으고 어린이 권리를
        옹호합니다.
      </p>
    </header>

    <h3>
      <Tit size="s2">유니세프 국가위원회</Tit>
    </h3>
    <Flex className="flex">
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.nl/">
          <p className="flag f1">네덜란드</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.no/">
          <p className="flag f2">노르웨이</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.org.nz/">
          <p className="flag f3">뉴질랜드</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.dk/">
          <p className="flag f4">덴마크</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.de/">
          <p className="flag f5">독일</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.lu/">
          <p className="flag f6">룩셈부르크</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicefusa.org/">
          <p className="flag f8">미국</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.be/">
          <p className="flag f9">벨기에</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.se/">
          <p className="flag f10">스웨덴</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.ch/">
          <p className="flag f11">스위스</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.es/">
          <p className="flag f12">스페인</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.sk/">
          <p className="flag f13">슬로바키아</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.si/">
          <p className="flag f14">슬로베니아</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.is/">
          <p className="flag f15">아이슬란드</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.ie/">
          <p className="flag f16">아일랜드</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.ad/">
          <p className="flag f17">안도라</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.org.uk/">
          <p className="flag f18">영국</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.org.au/">
          <p className="flag f19">오스트레일리아</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.at/">
          <p className="flag f20">오스트리아</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.org.il/">
          <p className="flag f21">이스라엘</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.it/">
          <p className="flag f22">이탈리아</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="https://www.unicef.or.jp/">
          <p className="flag f23">일본</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.cz/">
          <p className="flag f24">체코</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="https://www.unicef.ca/">
          <p className="flag f25">캐나다</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicefturk.org/">
          <p className="flag f26">튀르키예</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.pt/">
          <p className="flag f27">포르투갈</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.pl/">
          <p className="flag f28">폴란드</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.fr/">
          <p className="flag f29">프랑스</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.fi/">
          <p className="flag f30">핀란드</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.or.kr/">
          <p className="flag f31">한국</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.hu/">
          <p className="flag f32">헝가리</p>
        </LinkSafe>
      </li>
      <li>
        <LinkSafe target="_blank" to="http://www.unicef.org.hk/">
          <p className="flag f33">홍콩</p>
        </LinkSafe>
      </li>
    </Flex>
  </Content>
);

export default WorkTab3;
